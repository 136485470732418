.page-container {
    text-align: center;
}

.first-block-painting {
    background-image: url('/public/assets/painting-main.jpg');
    background-size: cover;
    background-position: bottom;
    max-width: 100%;
    max-height: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.first-block-painting button {
    width: 200px;
    margin: 0 auto;    
    font-size: 16px;
    margin-bottom: 20px;
    border-radius: 10px;
    transition: all 0.2s;
    animation: bounce 1.5s infinite;
  }
    
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
}

.first-block-painting h3 {
    font-size: 16px;
    color: rgb(0, 0, 0);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    letter-spacing: 1.5px;
}

.second-block {
    margin: 0 auto;
    margin-top: 40px;
}

.second-block h1 {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
}

.second-block h1::after {
    content: '';
    display: block;
    width: 250px;
    height: 2px;
    background-color: #999999;
    margin: 0 auto;
}

.second-block h2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 20px;
    padding: 10px;
}

.gallery-container img {
    margin: 0 auto;
    border: 1px solid #ddd;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.589);
    max-width: 100%;
    width: 400px;
    border-radius: 5px;
    margin-bottom: 50px;
}

.components-container {
    padding-bottom: 20px;
}

.social-medias {
    font-size: 50px;
    margin-top: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.see-more-projects h2 {
    margin-top: 40px;
    font-size: 16px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.sliders-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

@media (min-width: 768px) {
    .gallery-container {
        display: flex;
    }
    .first-block-painting {
        height: 600px;
        background-position: center;
    }
}

@media (min-width: 1100px) {
    .second-block h2 {
      padding-left: 10%;
      padding-right: 10%;
      align-items: center;
    }
  }