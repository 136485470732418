/* styles.css */
.review-container {
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
  }
  
  .review-container h2 {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
  }
    
    .review-block {
      border: 1px solid #e0e0e0;
      background-color: #4d76ff;
      box-shadow: 10px 4px 6px rgba(0, 0, 0, 0.37);
      padding: 15px;
      text-align: center;
      margin: 5px;
      border-radius: 30px;
      max-width: 300px;
      color: azure;
    }
  
    .review-block h3 {
      font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
  
  .star-rating {
      color: rgb(255, 221, 32);
      font-size: 25px;
  }
  
  .star-container {
    text-align: center;
  }
  
  @media (max-width: 550px) {
    .review-block {
      margin: 0 auto;
    }
  }