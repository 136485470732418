.homepage-container {
    align-items: center;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 30px;
  }
  
  .centered-content {
      background-image: url('/public/assets/main-photo-kairos.png');
      background-size: cover;
      background-position: center;
      max-width: 100%;
      max-height: 100%;
      height: 800px;
      display: flex;
      flex-direction: column;  
      justify-content: space-between;
  }
  
  .text-image {
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    max-width: 450px;
    margin: 0 auto;
    margin-top: 400px;
  }

  .text-image h2 {
    font-size: 22px;
  }
  
  .text-image h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
  
  .button-main-container {
    text-align: center;
    margin-bottom: 20px;

  }
  
  h2 {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  button {
    background-color: #007bff;
    font-weight: 600;
    color: white;
    padding: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 15px;
  }
  
  .btn-get-quote {
    transition: all 0.2s;
    animation: bounce 1.5s infinite;
    border-radius: 10px;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .weprovide-container {
      padding-top: 20px;
      text-align: center;
      font-size: 12px;
  }
  
  .weprovide-container h1 {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .weprovide-container h3 {
    margin: 5px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .weprovide-container h4 {
    font-size: 16px;
    font-weight: 100;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .licensed-insured {
    font-style: italic;
    font-size: 16px;
    padding-top: 20px;
    }
  
  h3 {
    font-weight: 400;
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  h4 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14px;
  }
  
  .title-services {
    text-align: center;
    padding-top: 20px;
  }
  
  .title-services h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
  }
  
  .title-services h1::after {
    content: '';
    display: block;
    width: 80px;
    height: 2px;
    background-color: #cccccc;
    margin: 0 auto;
  }
  
  .services-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 240, 240);
    padding-top: 10px;
  }

  .services-block h2 {
    font-size: 22px;
  }
  
  .text-services {
    flex: 1;
  }

  .text-services button {
    border-radius: 10px;
  }
  
  .img-services {
    text-align: center;
    margin-top: 30px;
    padding: 5px;
    margin: 10px;
    max-width: 100%;
    max-width: 500px;
  }

  .img-services img {
    cursor: pointer;
  }

  .services-block img {
    max-width: 100%;
    width: 450px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.466);
    margin-bottom: 5px;
    transition: transform 0.2s ease;
  }
  
  .services-block img:hover {
    transform: scale(1.05);
  }

  .off-selected-services h3 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 40px;
    text-align: center;
    letter-spacing: 1.5px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .centered-content {
      height: 600px;
    }
    .text-image {
      margin-top: 250px;
    }
    .services-block {
      flex-direction: column;
    }
    .text-services {
      text-align: center;
      margin-top: 10px;
    }
  }
  
  @media (min-width: 1100px) {
    .weprovide-container h3 {
      padding-left: 10%;
      padding-right: 10%;
      align-items: center;
    }
    .img-services {
      margin-inline: 10%;
    }
  }