.footer-container {
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 5px;
    text-align: center;
    background-image: url('/public/assets/IMG_6563.jpg');
    background-color: rgba(0, 0, 0, 0.7);
    background-size: cover;
    background-position: bottom;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.logo-company {
    align-items: center;
    margin: inherit;
}

.logo-company img {
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.507);
}

.logo-company h3 {
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.contact-container h2 {
    font-size: 20px;
}

.phone-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    line-height: 0.5;

}

.phone-email h2 {
    font-size: 20px;
}

.phone, .email {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.phone h3, .email h3 { 
    margin-left: 5px;
    line-height: 0;
}

.social-medias {
    font-size: 35px;
    margin-top: 5px;
    cursor: pointer;
}

h2 {
    font-size: 10px;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }
}