body {
    margin: 0;
  }
  
  .main-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-image: url('/public/assets/navbarg_bg.jpg');
      background-position: bottom;
      position: fixed;
      width: 100%;
      padding: 10px;
      z-index: 100;
    }
    
    .image-container a {
      display: block;
      text-decoration: none;
    }
    
    img {
      width: 60px;
      border-radius: 50%;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.507);
    }
    
    .nav-items {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      font-family: Arial;
    }
    
    .nav-items li {
      margin-right: 15px;
    }
    
    .nav-items a {
      text-decoration: none;
      color: rgb(255, 255, 255);
    }
    
    .dropdown {
      float: left;
      overflow: hidden;
    }
  
    .dropdown .dropbtn {
      font-size: 22px;  
      border: none;
      outline: none;
      padding: 14px 14px;
      background-color: inherit;
      font-family: inherit;
      margin: 0 auto;
    }
  
    .dropbtn {
      display: flex;
      align-items: center;
    }
  
    .navbar a:hover, .dropdown:hover .dropbtn {
      background-color: rgb(100, 100, 100);
    }
  
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #3b3b3b;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      border-radius: 10px;
    }  
  
    .dropdown-content a {
      float: none;
      color: rgb(255, 255, 255);
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      text-align: left;
    }
  
    .dropdown-content a:hover {
      background-color: #838383;
      border-radius: 10px;
    }
    
    .dropdown:hover .dropdown-content {
      display: block;
    }
    
    .get-quote-nav {
      margin-left: 10px;
      text-align: center;
    }
  
    .button-container {
      margin-left: 2px;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #007bff;;
      color: white;
      padding: 5px 10px;
      cursor: pointer;
      font-size: 18px;
      border-radius: 20px;
      font-weight: 700;
      max-width: 100%;
      text-align: center;
      transition: all 0.2s;
      animation: bounce 1.5s infinite;
    }
      
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-10px);
      }
      60% {
        transform: translateY(-5px);
      }
    }
  
  .icon-and-text {
    display: flex;
    align-items: center;
  }
  
  .phone-icon {
    font-size: 22px;
    margin-right: 2px;
  }
  
  .text-button {
    font-size: 18px;
  }
  
  @media (max-width: 550px) {
    .button-container {
      padding: 10px;
    }
    
    .phone-icon {
      font-size: 30px;
    }
    
    .text-button {
      font-size: 14px;
    }
  }